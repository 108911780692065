import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { Btn, Content, IconWrapper, Title, Wrapper } from "./OrcasBanner.styled";
type Props = {};

export default function OrcasBanner({}: Props) {
  const { t } = useTranslation("common");
  const [countryCode, setCountryCode] = useState(null);
  const [userDevice, setUserDevice] = useState(null);
  const { locale, push } = useRouter();
  useEffect(() => {
    if (typeof window !== "undefined") {
      let device = window.localStorage.getItem("BAIMS_IP_INFO");
      setUserDevice(JSON.parse(device));
      let code = window.localStorage.getItem("BAIMS_IP_INFORMATIONS");
      setCountryCode(JSON.parse(code));
    }
  }, []);

  const isDesktop = useMediaQuery({
    minWidth: 992.1,
  });

  const getCountryName = (code) => {
    switch (code) {
      case "kw":
        return "kuwait";
      case "ksa":
        return "ksa";
      case "uae":
        return "uae";
      default:
        return "";
    }
  };

  const getUrl = () =>
    getCountryName(countryCode?.actual_user_country_code).includes("kuwait")
      ? "https://go.orcas.io/3o8gPsQ"
      : "https://go.orcas.io/Dnlfup1";

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col col={12}>
            <Content>
              {isDesktop && (
                <IconWrapper>
                  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="32">
                    <path
                      d="M 22 0.5 L 44.717 0.527 C 51.116 0.527 56.338 5.835 56.338 12.386 C 56.338 15.624 55.051 18.711 52.808 20.969 C 52.219 21.459 51.594 21.911 50.932 22.287 C 48.726 23.68 45.674 25.638 46.262 28.311 L 46.63 30.042 L 45.674 28.574 C 43.835 25.751 39.495 24.32 32.766 24.32 L 22 24.5 C 15.565 24.5 10.5 19.088 10.5 12.5 C 10.5 5.912 15.565 0.5 22 0.5 Z"
                      fill="rgb(255,192,0)"
                    ></path>
                    <path
                      d="M 47.402 31.925 L 45.343 28.8 C 43.577 26.089 39.348 24.734 32.729 24.734 L 21 24.5 C 14.381 24.462 9 19.276 9 12.5 C 9 5.874 14.101 0.264 20.5 -0 L 21 -0 L 44.717 0.151 C 44.717 0.151 44.717 0.151 44.754 0.151 C 51.337 0.151 56.706 5.647 56.743 12.386 C 56.743 15.699 55.419 18.936 53.102 21.233 C 52.477 21.76 51.852 22.212 51.153 22.626 C 49.057 23.981 46.115 25.826 46.666 28.235 Z M 22 1 C 15.785 1 10.5 6.138 10.5 12.5 C 10.5 18.862 15.785 24 22 24 L 43 24 C 49.73 24 43.872 25.374 45.857 28.16 C 45.416 25.336 48.468 23.379 50.748 21.948 C 51.41 21.572 51.999 21.158 52.55 20.668 C 54.72 18.522 55.97 15.511 55.97 12.386 C 55.97 6.061 50.932 0.904 44.717 0.904 C 44.717 0.904 44.717 0.904 44.681 0.904 L 22.5 1 L 22 1 Z"
                      fill="rgb(0,0,0)"
                    ></path>
                  </svg>
                </IconWrapper>
              )}
              <Title>{t("orcas_banner")}</Title>
              <Btn
                variant="default"
                onClick={() => {
                  push(getUrl());
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14">
                  <path
                    d="M 15.572 0.003 C 15.572 0.003 11.219 1.439 9.141 2.287 C 8.87 2.398 8.651 2.597 8.484 2.84 C 8.339 3.05 8.302 3.319 8.37 3.564 L 8.37 3.568 C 8.961 5.739 9.529 9.185 9.833 11.381 C 9.874 11.681 10.215 11.83 10.458 11.654 C 10.869 11.354 11.448 10.987 11.799 10.743 C 13.331 9.678 14.492 8.906 14.657 7.278 C 15.188 2.054 15.39 2.362 15.909 0.359 C 15.965 0.141 15.795 -0.023 15.574 0.005"
                    fill="rgb(1, 154, 222)"
                  ></path>
                  <path
                    d="M 9.186 11.517 C 8.928 9.286 8.465 6.186 7.757 3.568 C 7.728 3.461 7.691 3.354 7.65 3.251 C 7.474 2.83 7.185 2.457 6.772 2.287 C 4.694 1.439 0.338 0.003 0.338 0.003 C 0.117 -0.023 -0.031 0.135 0.006 0.359 C 0.006 0.359 0.935 2.983 1.346 7.738 C 1.46 9.04 2.381 10.127 3.606 10.525 C 4.599 10.848 5.59 11.238 6.111 11.517 C 6.675 11.819 7.189 12.159 7.65 12.509 C 8.236 12.956 8.73 13.42 9.11 13.839 C 9.211 13.952 9.397 13.871 9.387 13.717 C 9.343 13.032 9.277 12.293 9.188 11.517"
                    fill="rgb(1, 154, 222)"
                  ></path>
                </svg>
                {t("orcas_banner_btn")}
              </Btn>
            </Content>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
